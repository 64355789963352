const binding = { modules: {}, dataActions: {} };

    const registerActionId = (actionPath) => {
        if (binding.dataActions[actionPath] &&
            binding.dataActions[actionPath].default &&
            binding.dataActions[actionPath].default.prototype &&
            binding.dataActions[actionPath].default.prototype.id) {
            binding.dataActions[binding.dataActions[actionPath].default.prototype.id] = binding.dataActions[actionPath];
        } else {
            Object.keys(binding.dataActions[actionPath] || {}).forEach(exportName => {
                if (binding.dataActions[actionPath][exportName] &&
                    binding.dataActions[actionPath][exportName].prototype &&
                    binding.dataActions[actionPath][exportName].prototype.Action) {
                    binding.dataActions[binding.dataActions[actionPath][exportName].prototype.id] = binding.dataActions[actionPath][exportName];
                }
            })
        }
    };

    const registerSanitizedActionPath = (sanitizedActionPath, dataAction) => {
        if (process.env.NODE_ENV === 'development') {
            if (!dataAction.default) {
                throw new Error('Data action path does not have a default export');
            }
            if (!(dataAction.default.prototype.id && binding.dataActions[dataAction.default.prototype.id]) || !binding.dataActions[sanitizedActionPath]) {
                binding.dataActions[sanitizedActionPath] = dataAction;
            }
        } else {
            binding.dataActions[sanitizedActionPath] = dataAction;
            if (!binding.dataActions[sanitizedActionPath].default) {
                throw new Error('Data action path ' + sanitizedActionPath + ' does not have a default export');
            }
            binding.dataActions[sanitizedActionPath].default.prototype.RegistrationId = sanitizedActionPath;
            if (binding.dataActions[sanitizedActionPath].default.prototype.id) {
                binding.dataActions[binding.dataActions[sanitizedActionPath].default.prototype.id] = sanitizedActionPath;
            }
        }
    };
    

    (binding.modules['dobbies-buybox-store-selector'] = {
        c: () => require('partner/modules/dobbies-buybox-store-selector/dobbies-buybox-store-selector.tsx'),
        $type: 'containerModule',
        da: [{name:'availabilitiesWithHours',  path:'@msdyn365-commerce-modules/retail-actions/dist/lib/get-full-available-inventory-nearby', runOn: 1},{name:'deliveryOptions',  path:'@msdyn365-commerce-modules/retail-actions/dist/lib/get-delivery-options-for-selected-variant', runOn: 0},{name:'getAllAvailableStoresInventory',  path:'actions/get-all-available-inventory-nearby', runOn: 1},{name:'storeLocations',  path:'@msdyn365-commerce-modules/retail-actions/dist/lib/get-store-location-information', runOn: 1},{name:'storeSelectorStateManager',  path:'@msdyn365-commerce-modules/bopis-utilities/dist/lib/store-selector-state-manager/store-selector-state-manager-data-action', runOn: 1}],
        
        iNM: false,
        ns: '__local__',
        n: 'dobbies-buybox-store-selector',
        p: '__local__',
        
        pdp: '',
        
        
        md: 'src/modules/dobbies-buybox-store-selector'
    });
        

        {
            const sanitizedActionPath = '@msdyn365-commerce-modules/bopis-utilities/dist/lib/store-selector-state-manager/store-selector-state-manager-data-action';
            let dataAction = require('@msdyn365-commerce-modules/bopis-utilities/dist/lib/store-selector-state-manager/store-selector-state-manager-data-action');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        {
            const sanitizedActionPath = '@msdyn365-commerce-modules/retail-actions/dist/lib/get-delivery-options-for-selected-variant';
            let dataAction = require('@msdyn365-commerce-modules/retail-actions/dist/lib/get-delivery-options-for-selected-variant');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        {
            const sanitizedActionPath = '@msdyn365-commerce-modules/retail-actions/dist/lib/get-full-available-inventory-nearby';
            let dataAction = require('@msdyn365-commerce-modules/retail-actions/dist/lib/get-full-available-inventory-nearby');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        {
            const sanitizedActionPath = '@msdyn365-commerce-modules/retail-actions/dist/lib/get-store-location-information';
            let dataAction = require('@msdyn365-commerce-modules/retail-actions/dist/lib/get-store-location-information');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        {
            const sanitizedActionPath = 'actions/get-all-available-inventory-nearby';
            let dataAction = require('partner/actions/get-all-available-inventory-nearby');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.dataActions = {
        ...window.__bindings__.dataActions || {},
        ...binding.dataActions
    };
                        export const viewDictionary = {};
                        viewDictionary['__local__|__local__|modules|dobbies-buybox-store-selector|dobbies-buybox-store-selector'] = {
                c: () => require('partner/modules/dobbies-buybox-store-selector/dobbies-buybox-store-selector.view.tsx'),
                cn: '__local__-__local__-dobbies-buybox-store-selector'
            };
window.__bindings__ = window.__bindings__ || {};
window.__bindings__.viewDictionary = {
                        ...window.__bindings__.viewDictionary || {},
                        ...viewDictionary
                    };